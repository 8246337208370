<template>
  <RevForm
    class="md:m-auto md:w-6/12"
    :initial-values="initialValues"
    :validate="validate()"
    @submit="submitEmail"
  >
    <template #default="{ values, errors, hasError }">
      <RevInputText
        id="email"
        v-model="values.email"
        autocomplete="email"
        class="mx-auto my-32 text-left"
        data-cs-mask
        :error="errors.email"
        :label="i18n(translations.email)"
        type="email"
        @focus="resetError"
      />
      <RevButton
        data-qa="password-reset-submit-button"
        data-test="password-reset-submit-button"
        :disabled="isSubmitDisabled(hasError)"
        full-width="always"
        :loading="loading"
        type="submit"
        variant="primary"
      >
        {{ i18n(translations.submit) }}
      </RevButton>
      <p v-if="error" class="text-action-danger-hi mt-16">
        {{ error }}
      </p>
    </template>
  </RevForm>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from '#imports'
import { ref } from 'vue'

import { useLostPassword } from '@backmarket/nuxt-layer-oauth/useLostPassword'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'

import { ROUTES } from '~/scopes/auth/route-names'

import { ERROR_MESSAGES } from './ResetPasswordForm.constants'
import translations from './ResetPasswordForm.translations'
import { type FormValues } from './ResetPasswordForm.types'

const route = useRoute()
const router = useRouter()
const i18n = useI18n()
const { lostPassword } = useLostPassword()
const tracking = useTracking()
const userStore = useUserStore()

const initialValues = ref({
  email: route.query?.email?.toString() || '',
})
const error = ref('')
const loading = ref(false)

function validate() {
  const requiredMessage = i18n(translations.requiredEmail)

  return makeValidate<FormValues>({
    email: [required(requiredMessage)],
  })
}

function resetError() {
  error.value = ''
}

function isSubmitDisabled(hasError?: boolean) {
  return hasError || !isEmpty(error.value) || loading.value
}

async function submitEmail(values: FormValues) {
  resetError()
  loading.value = true

  const { error: errorFetch } = await lostPassword(values.email)

  if (errorFetch.value) {
    const errorKey = errorFetch.value
      ? ERROR_MESSAGES[errorFetch.value.statusCode]
      : null

    error.value = errorKey ? i18n(errorKey) : i18n(translations.genericError)
  } else {
    userStore.setEmail(values.email)
    tracking.trackResetPassword()
    router.push({
      name: ROUTES.AUTH.RESET_PASSWORD_CONFIRM,
      query: route.query,
    })
  }

  loading.value = false
}
</script>
